* {
  font-family: Roboto, Verdana, Arial, sans-serif;
  color: #ffffff;
}

body {
  clear: left;
  width: 90vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10vh;
  background-color: #7ba17d;
}

body,
a:link,
a:visited {
  color: #ffffff;
}

a:active,
a:hover {
  color: #4b624c;
}

nav {
  margin-top: 3vh;
  margin-bottom: 4vh;
}

nav li {
  display: inline;
  list-style-type: none;
  padding-right: 3vw;
  font-size: xx-large;
}

nav li a {
  text-decoration: none;
}

nav li a i {
  vertical-align: bottom;
  color: #ffffff;
}

li a i:hover {
  color: #4b624c;
}

footer {
  text-align: center;
  font-size: x-small;
  color: #4b624c;
}
